import Axios, { GenericResponse } from "./Axios";

class SearchService {

    private axios: any;

    private static INSTANCE: SearchService = new SearchService();

    public static getInstance() {
        return SearchService.INSTANCE;
    }

    constructor() {
        this.axios = Axios;
    }

    public async searchLugarNacimiento(value: string): Promise<{ id: string, value: string }[]> {
        try {
            const response: { data: GenericResponse } = await this.axios({
                url: `collection/140/${value}`,
                method: "GET",
            })
    
            return response.data.values
        } catch (err) {
            throw new Error(err as string);
        }
    }

    public async searchNacionalidad(value: string): Promise<{ id: string, value: string }[]> {
        try {
            const response: { data: GenericResponse } = await this.axios({
                url: `collection/141/${value}`,
                method: "GET",
            })

            return response.data.values
        } catch (err) {
            throw new Error(err as string);
        }
    }

    public async searchList(searchUrl: string, value: string): Promise<{ id: string, value: string }[]> {
        try {
            const response: { data: GenericResponse } = await this.axios({
                url: `${searchUrl}/${value}`,
                method: "GET",
            })

            return response.data.values
        } catch (err) {
            throw new Error(err as string);
        }
    }
}

export default SearchService.getInstance();