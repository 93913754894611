import { AxiosError } from "axios";
import Axios, { GenericResponse } from "./Axios";
import { FormikVoluntario } from "./VoluntarioService";

class InscripcionService {
    private axios: any;

    constructor() {
        this.axios = Axios;
    }

    private static INSTANCE = new InscripcionService()

    public static getInstance(): InscripcionService {
        return this.INSTANCE;
    }

    public async register(values: FormikVoluntario): Promise<GenericResponse> {
        try {
            const data = {
                nombre: values.nombre,
                apellidos:  values.apellidos,
                genero: parseInt(values.genero),
                fechanacimiento: values.fechanacimiento,
                modalidad: parseInt(values.modalidad),
                tipodocumento: parseInt(values.datosPersonales.tipoDocumento),
                ndocumento: values.datosPersonales.tipoDocumentoValue,
                nacimiento: values.datosPersonales.lugarNacimiento.id,
                nacionalidad: values.datosPersonales.nacionalidad.id,
                dirección: values.datosPersonales.direccion,
                telefono1: values.datosPersonales.telefono1,
                telefono2: values.datosPersonales.telefono2,
                correoelectronico: values.datosPersonales.correoelectronico,
                carnetconducir: values.datosPersonales.carnet ? "on" : "off",
                situacionlaboral: parseInt(values.datosPersonales.situacionLabora),
                profesion: values.datosPersonales.profesion,
                nivelestudios: parseInt(values.formacion.nivelEstudios),
                idiomas: values.formacion.idiomas,
                hablacastellano: values.formacion.hablasEspanol ? "on" : "off",
                experienciaanterior: values.formacion.experenciaAnterior ? "on" : "off",
                experiencialaboral: values.formacion.experenciaLaboral ? "on" : "off",
                sector: values.formacion.sector,
                nuestra: parseInt(values.preferencias.nuestra),
                motivacion: values.preferencias.motivacionparaCambio,
                hobby: values.preferencias.hobby ? "on" : "off",
                hobbies: values.preferencias.hobbyValue,
                perfilatencion: parseInt(values.preferencias.perfilAtencion),
                areatrabajo: parseInt(values.preferencias.areaTrabajo),
                observaciones: values.preferencias.observaciones,
                lunes: values.disponibilidad.lunes ? "on" : "off",
                martes: values.disponibilidad.martes ? "on" : "off",
                miercoles: values.disponibilidad.miercoles ? "on" : "off",
                jueves: values.disponibilidad.jueves ? "on" : "off",
                viernes: values.disponibilidad.viernes ? "on" : "off",
                sabado: values.disponibilidad.sabado ? "on" : "off",
                domingo: values.disponibilidad.domingo ? "on" : "off",
                manana: values.disponibilidad.horarios.manana ? "on" : "off",
                tarde: values.disponibilidad.horarios.tarde ? "on" : "off",
                frecuencia: parseInt(values.disponibilidad.horarios.frecuencia),
                dedicacion: parseInt(values.disponibilidad.horarios.dedicacion),
                preferenciacontacto: parseInt(values.disponibilidad.horarios.preferenciacontacto),
                tipovoluntariado: parseInt(values.disponibilidad.horarios.tipoVoluntariado),
                observacionesvol: values.disponibilidad.horarios.observaciones
            }

            const response: { data: GenericResponse } = await this.axios({
                method: "PUT",
                url: `interface/prcVoluntario/frmVoluntario/${process.env.REACT_APP_API_KEY}`,
                data
            })

            if (response.data.context.criticalError) {
                throw new Error(response.data.context.criticalMessage)
            }

            return response.data;
        } catch (err) {
            const error = err as AxiosError
            throw new Error(error.message)
        }
    }
}

export default InscripcionService.getInstance()