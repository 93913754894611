import React, { useEffect } from "react";
import "../../styles/necesidades.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Routes from "../Routes";

export default function Confirmacion() {
    const navigate = useNavigate()
    const { state } = useLocation()

    useEffect(() => {
        if (!state) {
            navigate(Routes.INICIO)
        }
    }, [])

    if (!state) {
        return <></>
    }

    return (
        <div className="App-aceptada">
            <br />
            <h2>Inscripción de voluntariado realizada correctamente</h2>

            <br />
            <br />
            <br />
            <br />

            <p>
                Hemos recibido tu solicitud de inscripcion. Estamos confirmando disponibilidad. Tan pronto sea posible recibirás tu confirmación.
            </p>
        </div>
    );
}
