import Axios, { GenericResponse } from "./Axios";

interface GenericNecesidadesResponse extends GenericResponse {
    necesidades: NecesidadesServiceResponse[]
}

export interface NecesidadesServiceResponse {
    blockchain: string | null
    denominacion: string
    descripcionweb: string
    fechaDesde: string
    fechaHasta: string
    frecuencia: string
    id: string
    idNecesidadVoluntario: string
    lugar: string
    perfilDeAtencion: string
    plazas: string | null
    posicion: string
    tipoVoluntariado: string
}

class NecesidadesService {
    private axios: any;

    constructor() {
        this.axios = Axios;
    }

    private static INSTANCE = new NecesidadesService();

    public static getInstance(): NecesidadesService {
        return this.INSTANCE;
    }

    public async getNecesidades(): Promise<NecesidadesServiceResponse[]> {
        try {
            const response: { data: GenericNecesidadesResponse } = await this.axios({
                url: "codespavoluntario/necesidadvoluntarios",
                method: "POST",
                data: { token: process.env.REACT_APP_API_KEY }
            })

            return response.data.necesidades;
        } catch (err) { 
            throw new Error(err as string);
        }
    } 
}

export default NecesidadesService.getInstance();