import { FormikTouched, FormikErrors } from "formik";
import React from "react";
import { FormikVoluntario } from "../services/VoluntarioService";
import { CheckBoxProps, FieldProps, SelectProps } from "./Fields";

export default function Disponibilidad({ values, touched, errors }: {
    values: FormikVoluntario
    touched: FormikTouched<FormikVoluntario>
    errors: FormikErrors<FormikVoluntario>
}) {
    const daysOfWeek = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo']

    const convertToLabel = (value: string) => {
        const firstLetter = value.charAt(0).toUpperCase();
        return firstLetter + value.slice(1)
    }

    return (
        <>
            <div className="form-top">
                {daysOfWeek.map((day) => (
                    <CheckBoxProps 
                        key={day}
                        name={`disponibilidad.${day}`}
                        label={convertToLabel(day)}
                        // @ts-ignore
                        errors={errors.disponibilidad ? errors.disponibilidad[day] : undefined}
                        // @ts-ignore
                        touched={touched.disponibilidad ? touched.disponibilidad[day] : undefined}
                        // @ts-ignore
                        value={values.disponibilidad[day]}
                    />
                ))}
            </div>

            <p className="form-horario">Horarios</p>

            <div className="form-top" style={{ paddingBottom: 0 }}>
                <CheckBoxProps 
                    name="disponibilidad.horarios.manana"
                    label="Mañana"
                    errors={errors.disponibilidad?.horarios?.manana}
                    touched={touched.disponibilidad?.horarios?.manana}
                    value={values.disponibilidad.horarios.manana}
                />

                <CheckBoxProps 
                    name="disponibilidad.horarios.tarde"
                    label="Tarde"
                    errors={errors.disponibilidad?.horarios?.tarde}
                    touched={touched.disponibilidad?.horarios?.tarde}
                    value={values.disponibilidad.horarios.tarde}
                />  

                <SelectProps
                    id="Frecuencia"
                    name="disponibilidad.horarios.frecuencia"
                    label="Frecuencia"
                    value={values.disponibilidad?.horarios?.frecuencia}
                    errors={errors.disponibilidad?.horarios?.frecuencia}
                    touched={touched.disponibilidad?.horarios?.frecuencia}
                >
                    <option value="34457">Diaria</option>
                    <option value="34459">Mensual</option>
                    <option value="34458">Semanal</option>
                </SelectProps>

                <SelectProps
                    id="dedicacion"
                    name="disponibilidad.horarios.dedicacion"
                    label="Dedicación"
                    value={values.disponibilidad?.horarios?.dedicacion}
                    errors={errors.disponibilidad?.horarios?.dedicacion}
                    touched={touched.disponibilidad?.horarios?.dedicacion}
                >
                    <option value="34460">1 hora</option>
                    <option value="34461">2 horas</option>
                    <option value="34462">3 horas</option>
                    <option value="34463">4 horas</option>
                    <option value="34464">Más de 4 horas</option>
                </SelectProps>

                <SelectProps
                    id="preferenciacontacto"
                    name="disponibilidad.horarios.preferenciacontacto"
                    label="Preferencia contacto"
                    value={values.disponibilidad?.horarios?.preferenciacontacto}
                    errors={errors.disponibilidad?.horarios?.preferenciacontacto}
                    touched={touched.disponibilidad?.horarios?.preferenciacontacto}
                >
                    <option value="34465">Correo</option>
                    <option value="34466">Llamada</option>
                    <option value="34468">Mensaje de texto</option>
                    <option value="34467">Whatsapp</option>
                </SelectProps>

                <SelectProps
                    id="tipoVoluntariado"
                    name="disponibilidad.horarios.tipoVoluntariado"
                    label="Tipo Voluntariado"
                    value={values.disponibilidad?.horarios?.tipoVoluntariado}
                    errors={errors.disponibilidad?.horarios?.tipoVoluntariado}
                    touched={touched.disponibilidad?.horarios?.tipoVoluntariado}
                >
                    <option value="54785">Please choose one...</option>
                </SelectProps>
            </div>

            <div className="form-top" style={{ paddingTop: 0 }}>
                <FieldProps
                    id="horarios.observaciones"
                    name="disponibilidad.horarios.observaciones"
                    label="Observaciones"
                    type="textarea"
                    errors={errors.disponibilidad?.horarios?.observaciones}
                    touched={touched.disponibilidad?.horarios?.observaciones}
                    value={values.disponibilidad?.horarios?.observaciones}
                />
            </div>
        </>
    );
}
