import React, { useState } from "react";
import { Field, useFormikContext } from "formik"
import "../styles/components.scss"
import SearchService from "../services/SearchService";

const Form = ({ name, label, relevant, children, customClass }: { 
    name: string, 
    label: string, 
    relevant?: boolean 
    customClass?: string
    children: React.ReactNode
}) => (
    <div className={`form-control`}>
        <label htmlFor={name}>
            <span>
                {label}
                {relevant ? <span> * </span> : ''}
            </span>
            {children}
        </label>
    </div>
)

export const FieldProps = ({ name, id, type, value, placeholder, label, relevant, touched, errors, customClass, children, props }: {
    name: string
    id: string
    type: string
    value?: string
    placeholder?: string
    label: string
    relevant?: boolean
    touched: boolean | undefined
    errors: string | undefined
    customClass?: string
    children?: React.ReactNode
    [props: string]: any
}) => (
    <Form label={label} relevant={relevant} name={name} customClass={customClass}>
        <Field 
            name={name}
            id={id}
            type={type}
            value={value}
            placeholder={placeholder}
            component={type === "textarea" ? type : ''}
            className={`form-control${touched ? ' touched' : ''}${errors ? ' errors' : ''}`}
            rows={type === "textarea" ? "4" : ''}
            disabled={customClass ? true : false}
            {...props}
        />  
        {children}
        {touched && errors && <span className="form-error">{errors}</span>}
    </Form>
);

export const SelectProps = ({ name, id, value, label, relevant, touched, errors, children, component }: {
    name: string
    id: string
    value: string
    label: string
    relevant?: boolean
    touched: boolean | undefined
    errors: string | undefined
    children: React.ReactNode
    component?: string
}) => (
    <Form name={name} relevant={relevant} label={label}>
        <Field
            name={name}
            id={id}
            as="select"
            value={value}
            component={component}
            className={`form-control${touched ? ' touched' : ''}${errors ? ' errors' : ''}`}
        >
            <option value="0">...</option>
            {children}
        </Field>
        {touched && errors && <span className="form-error">{errors}</span>}
    </Form>
)

export const CheckBoxProps = ({ name, label, relevant, customClass, touched, errors, value }: {
    name: string
    label: string
    relevant?: boolean
    customClass?: string
    touched: boolean | undefined
    errors: string | undefined
    value: boolean 
}) => {
    const [check, setCheck] = useState(value)
    const { setFieldValue } = useFormikContext()

    const handleCheck = () => {
        setFieldValue(name, !check)
        setCheck(!check)
    }
 
    return (
        <Form name={name} label={label} relevant={relevant} customClass={customClass}>
            <div className={`checkbox${check ? ' checked' : ''}`} onClick={handleCheck}>
                {check ? 'Sí' : 'No'}
                <div className="checkbox-icon"></div>
            </div>
            {touched && errors && <span className="form-error">{errors}</span>}
        </Form>
    )
}

interface EachProps { id: string, value: string }

export const SearchBoxInput = ({ id, name, label,  relevant, customClass, placeholder, searchUrl, errors, touched }: {
    id: string
    name: string
    label: string
    searchUrl: string
    relevant?: boolean
    customClass?: string
    placeholder?: string
    errors?: any
    touched?: any
}) => {
    const [searchList, setSearchList] = useState<EachProps[]>([])
    const [selected, setSelected] = useState<EachProps>()
    const { setFieldValue } = useFormikContext()

    return (
        <Form name={`${name}.value`} label={label} relevant={relevant} customClass={customClass}>
            <Field name={`${name}.value`}>
                {({ field }: any) => {
                    return (
                        <>
                            <input 
                                id={id}
                                type="text" 
                                placeholder={placeholder} 
                                {...field}
                                onChange={(e) => {
                                    setFieldValue(name, e.target)

                                    SearchService.searchList(searchUrl, e.target.value)
                                        .then(response => setSearchList(response))
                                        .catch(() => setSearchList([]));
                                }}
                                autoComplete="off"
                                defaultValue={selected?.value}
                            />
                            {searchList.length > 0 && (
                                <ul className="form-list">
                                    {searchList.map(search => ( 
                                        <li 
                                            key={search.id} 
                                            onClick={() => {
                                                setFieldValue(name, search)
                                                setSearchList([])
                                                setSelected(search)
                                            }}
                                        >{search.value}</li>
                                    ))}
                                </ul>
                            )}
                            {touched && errors && <span className="form-error">{errors}</span>}
                        </>
                    )
                }}
            </Field>
        </Form>
    )
}
