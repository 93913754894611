import React, { useEffect, useState, useRef } from "react";
import { useLoadScript, GoogleMap, MarkerF, Autocomplete } from "@react-google-maps/api";
import { useFormikContext } from "formik";

const libraries = ['places']

interface LatLng {
    lat: number;
    lng: number;
}

export default function Map({ defaultDirection, marker, setMarker }: { 
    defaultDirection: string
    marker?: LatLng
    setMarker: React.Dispatch<React.SetStateAction<LatLng | undefined>> 
}) {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: `${apiKey}`,
        // @ts-ignore
        libraries
    })
    const { setFieldValue } = useFormikContext();
    const autoCompleteRef = useRef<HTMLInputElement>(null);
    const [searchResult, setSearchResult] = useState<any>()
    const [direction, setDirection] = useState(defaultDirection)

    useEffect(() => {
        setFieldValue('datosPersonales.direccion', direction)
    }, [direction])

    return isLoaded ? (
        <div className="map-container">
            <label htmlFor="map">Dirección</label>
            <div className="map-direction">
                <Autocomplete
                    onPlaceChanged={() => {
                        if (searchResult != null) {
                            const place = searchResult.getPlace();

                            if (place.formatted_address) {
                                setMarker({
                                    lat: place.geometry.location.lat(),
                                    lng: place.geometry.location.lng()
                                })

                                setDirection(place.formatted_address)
                            }
                        }
                    }}
                    onLoad={(autocomplete) => {
                        setSearchResult(autocomplete)
                    }}

                >
                    <input
                        type="text"
                        placeholder="Seleccione tu ubicación "
                        className="map-input"
                        id="map"
                        ref={autoCompleteRef}
                        defaultValue={direction}
                    />
                </Autocomplete>
            </div>

            <GoogleMap
                zoom={marker ? 15 : 10}
                center={{
                    lat: marker?.lat || 40.4637,
                    lng: marker?.lng || -3.7492,
                }}
                mapContainerClassName="map-style"
                onClick={(e) => {
                    setMarker({
                        lat: e.latLng?.lat() as number,
                        lng: e.latLng?.lng() as number 
                    })


                    const url = `https://maps.googleapis.com/maps/api/geocode/json?key=${apiKey}&latlng=${e.latLng?.lat()},${e.latLng?.lng()}&language=es`;

                    fetch(url)
                        .then((res) => res?.json())
                        .then((response) => {
                            if (response.results.length > 0) {

                                const address = response.results[0].formatted_address
                                
                                if (autoCompleteRef.current) {
                                    autoCompleteRef.current.value = address;
                                }
                            
                                setDirection(address as string);
                            }
                        })
                }}
            >
                {marker && (
                    <MarkerF
                        position={{
                            lat: marker.lat,
                            lng: marker.lng
                        }}
                    />
                )}
            </GoogleMap>
        </div>
    ) : <></>;
}
