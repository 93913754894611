import { FormikTouched, FormikErrors } from "formik";
import React from "react";
import { FormikVoluntario } from "../services/VoluntarioService";
import { CheckBoxProps, FieldProps, SelectProps } from "./Fields";

export default function Preferencias({ values, touched, errors }: {
    values: FormikVoluntario
    touched: FormikTouched<FormikVoluntario>
    errors: FormikErrors<FormikVoluntario>
}) {
    return (
        <>
            <div className="form-top" style={{ paddingBottom: 0 }}>
                <SelectProps
                    id="nuestra"
                    name="preferencias.nuestra"
                    label="¿Cómo nos conociste?"
                    touched={touched.preferencias?.nuestra}
                    errors={errors.preferencias?.nuestra}
                    value={values.preferencias.nuestra}
                >
                    <option value="34438">Conocido/a</option>
                    <option value="34437">Internet</option>
                    <option value="34440">Medios de comunicación</option>
                    <option value="34443">No sabe</option>
                    <option value="34439">Publicidad</option>
                    <option value="34441">Sede</option>
                    <option value="34442">Universidad</option>
                </SelectProps>
            </div>
            {/* 
            <div className="form-top" style={{ paddingTop: 0, paddingBottom: 0 }}>
                <FieldProps
                    id="motivacion"
                    name="preferencias.motivacionparaCambio"
                    label="Motivación para el cambio"
                    type="textarea"
                    relevant
                    value={values.preferencias.motivacionparaCambio}
                    errors={errors.preferencias?.motivacionparaCambio}
                    touched={touched.preferencias?.motivacionparaCambio}
                />
            </div> */}

            <div className="form-top" style={{ paddingTop: 0, paddingBottom: 0 }}>

                <SelectProps
                    id="perfilAtencion"
                    name="preferencias.perfilAtencion"
                    label="Perfil Atención"
                    touched={touched.preferencias?.perfilAtencion}
                    errors={errors.preferencias?.perfilAtencion}
                    value={values.preferencias.perfilAtencion}
                >
                    <option value="34445">Adultos</option>
                    <option value="34447">Adultos mayores</option>
                    <option value="34449">Familia</option>
                    <option value="34444">Infancia</option>
                    <option value="34446">Jóvenes</option>
                    <option value="34448">Mujeres</option>
                </SelectProps>            
    
                <SelectProps
                    id="areaTrabajo"
                    name="preferencias.areaTrabajo"
                    label="Área trabajo"
                    value={values.preferencias.areaTrabajo}
                    errors={errors.preferencias?.areaTrabajo}
                    touched={touched.preferencias?.areaTrabajo}
                >   
                    <option value="34451">Acompañamiento emocional</option>
                    <option value="34452">Acompañamiento gestiones</option>
                    <option value="34455">Cultura ocio</option>
                    <option value="34456">Digitalización</option>
                    <option value="34450">Educación</option>
                    <option value="34454">Logística</option>
                    <option value="34453">Sensibilización / Divulgación</option>
                </SelectProps>

                <CheckBoxProps 
                    name="preferencias.hobby"
                    label="Hobby"
                    errors={errors.preferencias?.hobby}
                    touched={touched.preferencias?.hobby}
                    value={values.preferencias.hobby}
                />

                <FieldProps
                    id="hobbyValue"
                    name="preferencias.hobbyValue"
                    label="¿cual?"
                    type="text"
                    value={values.preferencias.hobbyValue}
                    errors={errors.preferencias?.hobbyValue}
                    touched={touched.preferencias?.hobbyValue}
                    customClass={!values.preferencias.hobby ? 'show' : ''}
                />
            </div>
            
            <div className="form-top" style={{ paddingTop: 0}}>
                <FieldProps
                    id="observaciones"
                    name="preferencias.observaciones"
                    label="Observaciones"
                    type="textarea"
                    value={values.preferencias.observaciones}
                    errors={errors.preferencias?.observaciones}
                    touched={touched.preferencias?.observaciones}
                />
            </div>
        </>
    );
}
