import React from "react";
import { Link, Outlet } from "react-router-dom";
import Logo from "../assets/codespa-logo.svg";
import Footer from "../components/Footer";
import Routes from "./Routes";

export default function Root() {
    return (
        <div className="App" style={{ textAlign: "center" }}>
            <div className="App-image">
                <Link to={Routes.INICIO}>
                    <img src={Logo} className="image-header" alt="" />
                </Link>
                {/* <p className='title selected'>Inscripcion Voluntario</p> */}
            </div>

            <div className="App-body">
                <div className="App-body__inner">
                    <Outlet />
                </div>
            </div>

            <Footer />
        </div>
    );
}
