import React  from "react";
import { FormikTouched, FormikErrors  } from "formik"
import { FormikVoluntario } from "../services/VoluntarioService";
import { CheckBoxProps, FieldProps, SearchBoxInput, SelectProps } from "./Fields";
import Map from "./Map";

export default function DatosPersonales({ values, touched, errors, children }: {
    values: FormikVoluntario
    touched: FormikTouched<FormikVoluntario>
    errors: FormikErrors<FormikVoluntario>
    children: React.ReactNode
}) {
    const { datosPersonales } = values;

    return (
        <>
            <div className="form-top" style={{ paddingBottom: 0 }}>
                <SelectProps
                    id="tipoDocumento"
                    name="datosPersonales.tipoDocumento"
                    label="Tipo Documento"
                    touched={touched.datosPersonales?.tipoDocumento}
                    errors={errors.datosPersonales?.tipoDocumento}
                    value={datosPersonales.tipoDocumento}
                >
                    <option value="33909">DNI</option>
                    <option value="33912">Documentos en tramite</option>
                    <option value="33910">NIE</option>
                    <option value="33913">No tiene documentos</option>
                    <option value="33911">Pasaporte</option>
                </SelectProps>

                <FieldProps
                    id="tipoDocumentoValue"
                    name="datosPersonales.tipoDocumentoValue"
                    label="Nº Documento"
                    placeholder="Nº Documento"
                    type="text"
                    errors={errors.datosPersonales?.tipoDocumentoValue}
                    touched={touched.datosPersonales?.tipoDocumentoValue}
                    value={datosPersonales.tipoDocumentoValue}
                    customClass={
                        `${
                            (datosPersonales.tipoDocumento === "0" || 
                            datosPersonales.tipoDocumento === "33912" ||
                            datosPersonales.tipoDocumento === "33913") ? "show" : ""
                        }`
                    }
                />

                <SearchBoxInput 
                    id="lugarNacimiento"
                    name="datosPersonales.lugarNacimiento"
                    label="Lugar Nacimiento"
                    placeholder="Lugar Nacimiento"
                    searchUrl="collection/140"
                    errors={errors.datosPersonales?.lugarNacimiento}
                    touched={touched.datosPersonales?.lugarNacimiento}
                />

                <SearchBoxInput 
                    id="nacionalidad"
                    name="datosPersonales.nacionalidad"
                    label="Nacionalidad"
                    placeholder="Nacionalidad"
                    searchUrl="collection/141"
                    errors={errors.datosPersonales?.nacionalidad}
                    touched={touched.datosPersonales?.nacionalidad}
                />
            </div>

            {children}

            <div className="form-top" style={{ paddingTop: 0 }}>
                <FieldProps
                    id="telefono1"
                    name="datosPersonales.telefono1"
                    label="Teléfono 1"
                    placeholder="Telefono"
                    type="text"
                    errors={errors.datosPersonales?.telefono1}
                    touched={touched.datosPersonales?.telefono1}
                    value={datosPersonales.telefono1}
                />

                <FieldProps
                    id="telefono2"
                    name="datosPersonales.telefono2"
                    label="Teléfono 2"
                    placeholder="Telefono"
                    type="text"
                    errors={errors.datosPersonales?.telefono2}
                    touched={touched.datosPersonales?.telefono2}
                    value={datosPersonales.telefono2}
                />

                <FieldProps
                    id="correoelectronico"
                    name="datosPersonales.correoelectronico"
                    label="Correo electronico"
                    placeholder="Correo Electronico"
                    type="email"
                    errors={errors.datosPersonales?.correoelectronico}
                    touched={touched.datosPersonales?.correoelectronico}
                    value={datosPersonales.correoelectronico}
                />
                
                <CheckBoxProps 
                    label="Carnet conducir" 
                    name="datosPersonales.carnet" 
                    touched={touched.datosPersonales?.carnet}
                    errors={errors.datosPersonales?.carnet}
                    value={datosPersonales.carnet}
                />

                <SelectProps
                    id="situacionLaboral"
                    name="datosPersonales.situacionLabora"
                    label="Situación Labora"
                    errors={errors.datosPersonales?.situacionLabora}
                    touched={touched.datosPersonales?.situacionLabora}
                    value={datosPersonales.situacionLabora}
                >
                    <option value="34426">En activo</option>
                    <option value="34425">En paro</option>
                    <option value="34428">No sabe</option>
                    <option value="34424">Trabajando</option>
                    <option value="34427">Trabajo en situación ilegal</option>
                </SelectProps>

                <FieldProps
                    id="profesion"
                    name="datosPersonales.profesion"
                    label="Profesion"
                    placeholder="profesion"
                    type="text"
                    errors={errors.datosPersonales?.profesion}
                    touched={touched.datosPersonales?.profesion}
                    value={datosPersonales.profesion}
                />
            </div>
        </>
    );
}
