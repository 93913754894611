import React from "react";

function NotificationModal({ errorMessage, onCancel }: { 
    errorMessage: { header: string, body: string }
    onCancel: () => (void | Promise<void>) 
}) {
    if (!errorMessage.header) return <></>

    return (
        <div className="notification">
            <div className="notification_content">
                <div className="notification_content__header">{errorMessage.header}</div>
                <div className="notification_content__body">{errorMessage.body}</div>
                <button onClick={onCancel} style={{ width: '100%' }}>Reintentar</button>
            </div>
        </div>
    );
}

export default NotificationModal;
