import React, { useEffect, useState } from "react";
import NecesidadesService, { NecesidadesServiceResponse } from "../../services/NecesidadesService";
import { FiCheck } from "react-icons/fi";
import "../../styles/necesidades.scss";
import { useNavigate } from "react-router-dom";
import Routes from "../Routes";

export default function NecesidadesScreen() {
    const navigate = useNavigate()
    const [selectedList, setSelectedList] = useState<string[]>([])
    const { list } = useNecesidadList()

    return (
        <div className="App-necesidades">

            <h1>Necesidades de voluntariado</h1>
            <span>Seleccione al menos una necesidad de su interes</span>

            <br />  

            <div className="App-necesidades__list">
                {list.length > 0 && list.map(item => (
                    <div 
                        className={`necesidades-card${selectedList.indexOf(item.id) !== -1 ? ' selected' : ''}`} 
                        key={item.id}
                        onClick={() => setSelectedList(current => {
                            const checkList = current.indexOf(item.id) === -1

                            if (checkList) {
                                return [ ...current, item.id ]
                            } else {
                                return current.filter(each => each !== item.id)
                            }
                        })}
                    >
                        <h4 className="necesidades-card__title">{item.denominacion}</h4>
                        <p>{item.descripcionweb}</p>

                        <div className="necesidades-card__direction">
                            <p>Dirección:</p>
                            <a 
                                href={`https://maps.google.com/maps?q=${item.lugar}`} 
                                target='_blank'
                            >{item.lugar}</a>
                        </div>

                        <div className="necesidades-card__infos">
                            <div>
                                <p>Tipo de Voluntariado:</p>
                                <span>{item.tipoVoluntariado}</span>
                            </div>
                            <div>
                                <p>Frecuencia:</p>
                                <span>{item.frecuencia}</span>
                            </div>
                        </div>

                        <div className="necesidades-card__infos">
                            <div>
                                <p>Plazas:</p>
                                <span>{item.plazas}</span>
                            </div>
                            <div>
                                <p>Perfil de Atención:</p>
                                <span>{item.perfilDeAtencion}</span>
                            </div>
                        </div>

                        {selectedList.indexOf(item.id) !== -1 && (
                            <div className="selected-icon">
                                <FiCheck size={45} color="white" />
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <br />

            <div className="App-necesidades__btn">
                <button 
                    className={`each-button${!(selectedList.length > 0) ? " disabled" : ""}`}
                    onClick={() => navigate(Routes.FORMULARIO)}
                    disabled={!(selectedList.length > 0)}
                >Continuar</button>
            </div>
        </div>
    );
}

const useNecesidadList = () => {
    const [list, setList] = useState<NecesidadesServiceResponse[]>([])

    useEffect(() => {
        NecesidadesService.getNecesidades()
            .then(response => setList(response))
            .catch(error => console.log(error))
    }, [])

    return { list }
}