import React  from 'react';
import { RouterProvider } from 'react-router-dom'
import { createHashRouter } from 'react-router-dom';
import Root from './routes/Root';
import FormularioInscripcionScreen from './routes/formulario/FormularioInscripcionScreen';
import './styles/App.scss';
import NecesidadesScreen from './routes/necesidades/NecesidadesScreen';
import Routes from './routes/Routes';
import Confirmacion from './routes/confirmacion/Confirmacion';

const router = createHashRouter([
    {
        path: '/',
        element: <Root />,
        errorElement: <h1>There is an error page </h1>,
        children: [
            {
                path: Routes.INICIO,
                element: <NecesidadesScreen />
            },
            {
                path: Routes.FORMULARIO,
                element: <FormularioInscripcionScreen />
            },
            {   
                path: Routes.CONFIRMACION,
                element: <Confirmacion />
            }   
        ]
    },
])

function App() {
    return <div className="App"><RouterProvider router={router} /></div>;
}

export default App;
