import axios from "axios";

export interface GenericResponse {
    context: {
        criticalError: boolean
        blockChainHash: string
        blockChainList: null
        criticalMessage: string
        criticalType: string
        mensajesError: any[]
        listaPermisos: {}
        entities: {}
        values: {}
        funcionalidades: {
            roles: boolean
            usuarios: boolean
            pacientes: boolean
            traza: boolean
            estadistica: boolean
            entidades: boolean
            calendario: boolean
            eficiencias: boolean
            organizaciones: boolean
            tablas: boolean
            coleccionesExternas: boolean
            gestorServicios: boolean
            plantillas: boolean
            trabajadores: boolean
            administrador: boolean
            menus: boolean
            exportforms: boolean
            importforms: boolean
            templatefields: boolean
            appfunctionalities: {}
        }
        openThreads: number
        openConnections: number
    }
    values: {
        id: string
        value: string
    }[]
}

export default axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/activiti-app/services/`,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
})
