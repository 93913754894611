import React from "react";
import { CheckBoxProps, FieldProps, SelectProps } from "./Fields";
import { FormikTouched, FormikErrors } from "formik";
import { FormikVoluntario } from "../services/VoluntarioService";

export default function Formacion({ values, touched, errors }: {
    values: FormikVoluntario
    touched: FormikTouched<FormikVoluntario>
    errors: FormikErrors<FormikVoluntario>
}) {
    return (
        <div className="form-top">
            <SelectProps
                id="nivelEstudios"
                name="formacion.nivelEstudios"
                label="Nivel Estudios"
                value={values.formacion.nivelEstudios.toString()}
                errors={errors.formacion?.nivelEstudios}
                touched={touched.formacion?.nivelEstudios}
            >
                <option value="34432">Bachillerato</option>
                <option value="34435">E. Especial</option>
                <option value="34429">E. Infantil</option>
                <option value="34430">E. Primaria</option>
                <option value="34431">E. Secuandaria</option>
                <option value="34434">E. Universitaria</option>
                <option value="34433">F. Profesional</option>
                <option value="34436">Sin estudios</option>
            </SelectProps>

            <FieldProps
                id="idiomas"
                name="formacion.idiomas"
                label="Idiomas"
                placeholder="Idiomas"
                type="text"
                errors={errors.formacion?.idiomas}
                touched={touched.formacion?.idiomas}
                value={values.formacion?.idiomas}
            />

            <CheckBoxProps 
                name="formacion.hablasEspanol" 
                label="¿Hablas español?" 
                touched={touched.formacion?.hablasEspanol}
                errors={errors.formacion?.hablasEspanol}
                value={values.formacion.hablasEspanol}
            />

            <CheckBoxProps 
                name="formacion.experenciaAnterior" 
                label="Experiencia anterior" 
                touched={touched.formacion?.experenciaAnterior}
                errors={errors.formacion?.experenciaAnterior}
                value={values.formacion.experenciaAnterior}
            />

            <CheckBoxProps 
                name="formacion.experenciaLaboral"
                label="Experiencia laboral"
                errors={errors.formacion?.experenciaLaboral}
                touched={touched.formacion?.experenciaLaboral}
                value={values.formacion.experenciaLaboral}
            />

            <FieldProps
                id="sector"
                name="formacion.sector"
                label="Sector"
                placeholder="Sector"
                type="text"
                errors={errors.formacion?.sector}
                touched={touched.formacion?.sector}
                value={values.formacion?.sector}
                customClass={!values.formacion.experenciaLaboral ? 'show' : ''}
            />
        </div>
    );
}
